<template>
  <div :class="customClass">
    <DetailRow>
      <template #info>{{ $t('realm') }}</template>
      <template #value>{{ discipline }}</template>
    </DetailRow>
    <DetailRow>
      <template #info>{{ $t('range') }}</template>
      <template #value>{{ extent }}</template>
    </DetailRow>
    <DetailRow>
      <template #info>{{ $t('specialty') }}</template>
      <template #value>{{ speciality }}</template>
    </DetailRow>
  </div>
</template>

<script>
import DetailRow from './DetailRow'
export default {
  name: 'PersonSpecialities',
  components: { DetailRow },
  props: {
    discipline: {
      default: '',
      type: String,
      required: false
    },
    extent: {
      default: '',
      type: String,
      required: false
    },
    speciality: {
      default: '',
      type: String,
      required: false
    },
    customClass: {
      default: '',
      type: String,
      required: false
    }
  }
}
</script>
