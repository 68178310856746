<template>
  <div class="max-w-screen-xl mx-auto px-3 sm:px-6 lg:px-8 pt-8">
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <template v-if="getPerson">
        <div class="px-4 py-5 border-b border-gray-200 sm:px-6 bg-indigo-600">
          <h1 class="text-lg leading-6 font-medium text-white">
            {{ $t('permissions') }}
          </h1>
          <p class="mt-1 max-w-2xl text-sm leading-5 text-white">
            {{ $t('personal_data') }}
          </p>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 mb-4 border-b border-r">
            <dl class="border-b">
              <DetailRow v-for="(el, index) in getPersonalInfo" :key="index">
                <template #info>{{ el.info }}</template>
                <template #value>{{ el.value }}</template>
              </DetailRow>
            </dl>
            <dl>
              <DetailHeader
                ><template #info>{{
                  $t('mailing_address')
                }}</template></DetailHeader
              >
              <DetailRow v-for="(el, index) in getMailingAddress" :key="index">
                <template #info>{{ el.info }}</template>
                <template #value>{{ el.value }}</template>
              </DetailRow>
            </dl>
          </div>

          <div class="w-full md:w-1/2 mb-4 border-b">
            <dl>
              <DetailRow customClass="border-b">
                <template #info>{{ $t('decision_number') }}</template>
                <template #value>{{ getPerson.decision_number }}</template>
              </DetailRow>
              <template v-for="(spec, index) in getPerson.specialities">
                <PersonSpecialities
                  :key="index"
                  :class="[getPerson.specialities.length > 1 ? 'border-t' : '']"
                  :discipline="spec.discipline"
                  :extent="spec.extent"
                  :speciality="spec.speciality"
                ></PersonSpecialities>
              </template>
              <DetailRow>
                <template #info>{{ $t('membership') }}</template>
                <template #value v-if="getPerson.memberships.length > 0">
                  <div
                    v-for="(item, index) in getPerson.memberships"
                    :key="index"
                  >
                    <p>{{ item }}</p>
                  </div>
                </template>
                <template v-else #value>
                  <p>-</p>
                </template>
              </DetailRow>
            </dl>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="bg-indigo-600">
          <div class="flex flex-col justify-center items-center py-3 mb-4">
            <img
              class="h-24 p-4 mb-4"
              :alt="$t('header_message2')"
              src="../assets/logo-black.png"
            />
            <h1 class="text-2xl text-white">{{ this.$t('header_message') }}</h1>
            <p
              class="mx-3 font-medium text-white py-12 text-xl text-center"
            ></p>
          </div>
        </div>
      </template>
      <div class="flex justify-between align-content-center">
        <p
          v-html="this.$t('faq_link_detail')"
          class="text-blue-900 pb-4 pl-6 text-md left
          transition duration-100 ease-in-out transform hover:-translate-1 hover:scale-110"
        ></p>
        <router-link
          class="w-auto transition duration-100 ease-in-out text-blue-900
          transform hover:-translate-1
          hover:scale-110 pb-4 pr-6"
          to="/"
        >
          {{ $t('return_to_search_page') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import DetailRow from '../components/DetailRow'
import DetailHeader from '../components/DetailHeader'
import PersonSpecialities from '../components/PersonSpecialities'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Detail',
  components: { PersonSpecialities, DetailRow, DetailHeader },
  props: {
    person: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      persons: 'professionalCards/mapResults'
    }),
    getPerson() {
      return this.person || this.persons[0]
    },
    getPersonalInfo() {
      return this.getPerson
        ? [
            { info: this.$t('names'), value: this.getPerson.names },
            {
              info: this.$t('professional_title'),
              value: this.getPerson.professional_title
            },
            {
              info: this.$t('academic_degree'),
              value: this.getPerson.academic_degree
            },
            {
              info: this.$t('primary_voivodeship'),
              value: this.getPerson.primary_voivodeship
            }
          ]
        : null
    },
    getMailingAddress() {
      return this.getPerson
        ? [
            { info: this.$t('country'), value: this.getPerson.country },
            { info: this.$t('region'), value: this.getPerson.voivodeship },
            { info: this.$t('post_code'), value: this.getPerson.zip_code },
            { info: this.$t('post'), value: this.getPerson.post_office },
            { info: this.$t('town'), value: this.getPerson.place },
            { info: this.$t('street'), value: this.getPerson.street },
            {
              info: this.$t('home_number'),
              value: this.getPerson.house_number
            },
            {
              info: this.$t('apartment_number'),
              value: this.getPerson.apartment_number
            },
            { info: this.$t('phone'), value: this.getPerson.phone_number },
            { info: this.$t('email'), value: this.getPerson.email_address }
          ]
        : null
    }
  },
  methods: {
    ...mapActions({
      search: 'professionalCards/getProfessionalCards'
    }),
    findPerson() {
      const route = this.$route.params.id.toString()
      if (!this.person) {
        this.search({ 'filter[id]': route })
      }
    }
  },
  created() {
    this.findPerson()
  }
}
</script>
